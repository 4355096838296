.working-process-wrap {
    display: flex;
    justify-content: space-between;
}
.single-working-process{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 1%;
}
.single-working-process .count-block {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    color: #fd6b00;
    font-size: 22px;
    font-weight: 500;
    background-color: #fff;
    margin-bottom: 20px;
}
.single-working-process h3 {
    margin: 0;
    color: #222222;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 20px;
}
.single-working-process p {
    margin: 0;
    color: #777;
    font-size: 15px;
    line-height: 26px;
}