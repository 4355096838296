@media screen and (max-width: 1200px) {
    /* p {
        font-size: 16px;
    } */
    div.container {
        padding: 0 50px;
    }
    .header-navigation {
        padding: 0;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    p {
        font-size: 14px;
    }
    /* div.container {
        padding: 0 50px;
    } */
    .header-logo {
        width: 160px;
    }
    .awssld {
        height: 430px;
    }
    /* .about-section {
        align-items: center;
    } */
    .about-lists {
        flex-flow: column;
    }
    .title-block {
        margin-bottom: 20px;
    }
    .title-block h2 {
        font-size: 30px;
    }
    .title-block .tag-line {
        position: relative;
    }
    .title-block .tag-line:before {
        position: absolute;
        left: 0;
        bottom: -4px;
    }
    .list-item {
        padding-right: 0;
        line-height: 30px;
        margin: 0;
    }
    .list-item li {
        font-size: 14px;
    }
    .about-section, .service-style .title-block, .single-service {
        align-items: center;
    }
    .services, .footer-content {
        display: grid;
        grid-template-columns: 48% 48%;
    }
    .single-service p {
        text-align: center;
    }
    /* .footer-content {
        display: grid;
        grid-template-columns: 48% 48%;
    } */
}
@media screen and (max-width: 768px) {
    p {
        font-size: 14px;
    }
    div.container {
        padding: 0 50px;
    }
    .section-padding {
        padding: 80px 0;
    }
    .awssld {
        height: 300px;
    }
    .title-block {
        align-items: center;
        margin-bottom: 10px;
    }
    .title-block .tag-line {
        position: relative;
    }
    .title-block .tag-line:before {
        position: absolute;
        bottom: -2px;
    }
    .title-block h2 {
        font-size: 26px;
    }
    .content-block p {
        font-size: 14px;
        margin: 20px 0;
    }
    .about-lists {
        flex-flow: column;
    }
    .list-item {
        line-height: 30px;
        padding: 0;
        margin: 0;
    }
    .list-item li {
        font-size: 14px;
    }
    .image-block {
        margin-top: 30px;
    }
    .single-service {
        align-items: center;
    }
    .single-service .icon-block:before {
        left: -15px;
    }
    .single-service .icon-block svg {
        left: -2px;
        position: relative;
    }
    .single-service h3 {
        font-size: 20px;
        margin-bottom: 15px;
    }
    .single-service p {
        text-align: center;
    }
    .footer-widget-title {
        margin-bottom: 10px;
    }
    .footer-widget-title h3 {
        font-size: 20px;
    }
    .single-footer-col ul li a {
        font-size: 14px;
    }
    .bottom-footer p {
        font-size: 12px;
    }

}

