.footer-section {
    background-color: #161827;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(http://smartdemowp.com/cameron/wp-content/themes/cameron/assets/images/resources/footer-map-1-1.png);
    color: #fff;
}

.main-footer {
    padding: 50px 0;
}

.footer-content {
    display: flex;
}

.footer-widget-title {
    margin-bottom: 30px;
}

.footer-widget-title h3 {
    margin: 0;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
}

.single-footer-col {
    text-align: left;
    flex: 1;
    padding: 0 10px;
}

.footer-logo {
    width: 200px;
}

.single-footer-col ul {
    padding-left: 0;
    list-style: none;
    line-height: 30px;
}

.single-footer-col ul li a{
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
}

.single-footer-col ul li a svg {
    padding-right: 10px;
}

.newsletter-form p {
    margin-bottom: 25px;
}

.newsletter-form form {
    display: flex;
    justify-content: space-evenly;
}

.newsletter-form form input {
    width: 65%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #fff;
    background: #fff;
    margin-right: 5px;
}

.newsletter-form form button {
    width: 25%;
    border-radius: 5px;
    border: 1px solid #fd6b00;
    background: #fd6b00;
    color: #fff;
    font-weight: 700;
}

.bottom-footer {
    padding: 20px 0;
    background-color: rgba(22, 24, 39, 0.8);
    box-shadow: 0px -1px 0px 0px rgb(255 255 255 / 10%);
}

.bottom-footer p {
    margin: 0;
    font-size: 12px;
}