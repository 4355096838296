.about-style{
    padding-bottom: 60px;
}

.about-section {
    display: flex;
}

.title-block {
    margin-bottom: 30px;
}

.title-block .tag-line {
    display: inline-block;
    vertical-align: middle;
    color: #fd6b00;
    font-size: 20px;
    font-weight: 500;
    line-height: 1em;
}

.title-block .tag-line:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 2px;
    background-color: #fd6b00;
    margin-right: 20px;
}

.title-block h2 {
    margin: 0;
    color: #222222;
    font-size: 42px;
    line-height: 50px;
    font-weight: 600;
    margin-top: 10px;
}

.about-section {
    display: flex;
}

.image-block {
    position: relative;
}

.image-block img{
    width: 100%;
    height: auto;
    margin-top: 10%;
}

.content-block p {
    text-align: left;
}

.about-lists {
    display: flex;
}

.list-item {
    list-style: none;
    text-align: left;
    padding-left: 0;
    padding-right: 40px;
    line-height: 40px;
}

.list-item li {
    display: flex;
    align-items: center;
}

.list-item li svg {
    margin-right: 10px;
    color: #fd6b00;
}
