.topbar {
  background: #fd6b00;
  padding: 5px;
}
.topbar-content {
  display: flex;
  justify-content: space-between;
}
.topbar-section a{
  color: #fff;
  font-size: 13px;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.topbar-section a svg{
  margin-right: 4px;
}
.header-navigation{
    /* padding:  0 20px; */
    background: #fff;
    box-shadow: 0px 5px 30px rgb(0 0 0 / 5%);
}
.header-navigation.stricky-fixed {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 991;
    border-bottom: 0;
}
.header-navigation div.navbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-logo{
    width: 200px;
}
.header-navigation .Header_links{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-navigation .Header_links .Header_link{
    display: inline-block;
    padding: 5px 35px;
}
.header-navigation .Header_links .Header_link a{
    color: #0d1136;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
}

.header-navigation .right-side-box {
    align-items: center;
    display: flex;
}

.header-navigation .right-side-box .social{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-navigation .right-side-box .social a {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #f5f5f5;
    color: #fd6b00;
    line-height: 52px;
    font-size: 14px;
    text-align: center;
    transition: all .4s ease;
    margin-right: 10px;
}

.header-navigation .right-side-box .social a:hover {
    background: #fd6b00;
    color: #fff;
}

.hamburger {
    height: 35px;
    width: 35px;
    padding: 6px;
    border-radius: 3px;
    background-color: #fd6b00;
    border: 2px solid #fd6b00;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    color: #fff;
    display: none;
    align-items: center;
    justify-content: center;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}
  .hamburger:hover {
    background-color: #fff;
    color: #fd6b00;
  }

  .hamburger svg {
    font-size: 18px;
  }

  .navigation-menu ul {
    display: flex;
    padding: 0;
  }


/* Responsive */
@media screen and (max-width: 991px) {
    .header-navigation {
        padding: 0;
    }
    .header-navigation .Header_links .Header_link {
        padding: 5px 25px;
    }
    .header-navigation .Header_links .Header_link a {
        font-size: 12px;
    }
    .header-navigation .right-side-box .social a {
        width: 35px;
        height: 35px;
        line-height: 38px;
    }
}
@media screen and (max-width: 768px) {
    .hamburger {
        display: flex;
    }
    .header-navigation .Header_links, .header-navigation .right-side-box {
        display: none;
    }
    .navigation-menu ul {
        display: none;
    }
    .navigation-menu.expanded .Header_links {
        display: flex;
        position: absolute;
        top: 54px;
        background: #fff;
        left: 0;
        flex-flow: column;
        width: 100%;
    }
    .about-section, .services, .footer-content {
        flex-flow: column;
    }
}


/* .navigation {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #fff;
    color: black;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  }

  .navigation-menu {
    margin-left: auto;
  }

  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }

  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  .hamburger:hover {
    background-color: #2642af;
  }



  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
    .navigation-menu ul {
        display: none;
    }
    .navigation-menu ul {
        position: absolute;
        top: 60px;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 77px);
        background-color: white;
        border-top: 1px solid black;
      }
      .navigation-menu li {
        text-align: center;
        margin: 0;
      }
      .navigation-menu li a {
        color: black;
        width: 100%;
        padding: 1.5rem 0;
      }
      .navigation-menu li:hover {
        background-color: #eee;
      }
      .navigation-menu.expanded ul {
        display: block;
      }
  } */