.testimonial {
    display: flex;
    justify-content: center;
    align-items: center;
}
.testimonial .title-block {
    align-items: center;
    margin-bottom: 0;
}
.testimonial .title-block .tag-line {
    display: inline-block;
    vertical-align: middle;
    color: #fd6b00;
    font-size: 20px;
    font-weight: 500;
    line-height: 1em;
}
.testimonial .title-block .tag-line::before,
.testimonial .title-block .tag-line::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 2px;
    background-color: #fd6b00;
}
.testimonial .title-block .tag-line::before{
    margin-right: 20px;
}
.testimonial .title-block .tag-line::after{
    margin-left: 20px;
}
.testimonial .title-block h2{
    margin: 0;
    color: #222222;
    font-size: 42px;
    line-height: 50px;
    font-weight: 600;
    margin-top: 10px;
}
.single-testimonials-one {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 880px;
    text-align: center;
}
.single-testimonials-one .image-block img {
    max-width: none;
    width: 10%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border-style: solid;
    border-width: 5px;
    border-color: #dfe6df;
}
.single-testimonials-one p {
    color: #777777;
    line-height: 28px;
    font-size: 17px;
    margin-top: 35px;
    margin-bottom: 40px;
}
.single-testimonials-one h3 {
    font-size: 20px;
    color: #222222;
    margin-bottom: 10px;
}
.single-testimonials-one p.designation {
    color: #777777;
    font-size: 15px;
    line-height: 1em;
    margin: 0;
}
.testimonial .slick-slider {
    max-width: 880px;
    margin-top: -35px;
}

.testimonial .slick-prev:before, 
.testimonial .slick-next:before {
    color: #fd6b00;
    font-size: 30px;
}
.testimonial .slick-next,
.testimonial .slick-prev{
    top: 60%;
}
.testimonial .slick-next {
    right: -15%;
}
.testimonial .slick-prev {
    left: -15%;
}