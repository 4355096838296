.client-slider {
    background: #fbfbfb;
}
.client-slider .slick-initialized .slick-slide {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}
.client-slider .slick-track{
    display: flex;
    justify-content: center;
    align-items: center;
}
.client-slider .slick-slide img {
    width: 65%;
}