.banner {
    width: 100%;
    height: 300px;
}

.awssld {
    height: 550px;
}

.awssld p {
    font-size: 32px;
    color: #fff;
    position: relative;
    font-weight: 700;
    /* left: 0;
    right: 0;
    bottom: 0; */
}