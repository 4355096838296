.service-style {
    position: relative;
    background-color: #ebf0eb;
}

.service-style .circle-one {
    position: absolute;
    right: -300px;
    top: -170px;
    width: 600px;
    height: 600px;
    border: 80px solid #e6ebe6;
    border-radius: 50%;
}

.title-block {
    z-index: 1;
    position: relative;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title-block .tag-line {
    display: inline-block;
    vertical-align: middle;
    color: #fd6b00;
    font-size: 20px;
    font-weight: 500;
    line-height: 1em;
}

.title-block .tag-line::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 2px;
    background-color: #fd6b00;
    margin-right: 20px;
}

.title-block h2 {
    margin: 0;
    color: #222222;
    font-size: 42px;
    line-height: 50px;
    font-weight: 500;
    margin-top: 10px;
}

.services{
    display: flex;
}

.single-service{
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding: 1%;
}

.single-service:hover .icon-block:before {
    transform: scale(1.5);
}

.single-service .icon-block {
    position: relative;
    padding-top: 31px;
    margin-bottom: 20px;
}

.single-service .icon-block:before {
    position: absolute;
    content: '';
    left: 13px;
    top: 13px;
    height: 70px;
    width: 70px;
    background-image: -webkit-linear-gradient( -142deg, rgb(227 101 49 / 22%) 0%, rgb(201 139 78 / 0%) 100%);
    border-radius: 50%;
    transition: .5s;
}

.single-service .icon-block svg{
    font-size: 45px;
    color: #fd6b00;
}

.single-service h3{
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 17px;
}

.single-service p{
    color: #848484;
    font-size: 15px;
    line-height: 26px;
    margin: 0;
    margin-bottom: 14px;
    text-align: left;
}
.counter {
    background: url(https://smartdemowp.com/cameron/wp-content/uploads/2020/06/fun-fact-bg-1-1-2.jpg) center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}
.counter-bg {
    background:rgba(0,0,0,0.8);
}
.counter-info {
    display: flex;
    align-items: center;
    height: 50vh;
    justify-content: space-between;
}
.counter-details {
    display: flex;
    align-items: center;
    color: #fff;
}
.counter-icon-container {
    padding: 0 15px;
}
.counter-icon {
    font-size: 50px;
}
.counter-data h1 {
    font-size: 4rem;
    margin: 0;
}
.counter-data p {
    font-size: 1.7rem;
    margin: 0;
}